<template>
  <div class="edit">
    <!--顶部-->
    <header-nav :current="current"></header-nav>
    <div class="content" ref="content">
      <div class="box clearfix">
        <user-left :name="'apply'"></user-left>
        <div class="user-right" ref="right">
          <div class="title-top"><span>资质申请列表</span></div>
          <div class="table-box">
            <table>
              <tr class="a-ta-c">
                <td class="tx-l">测评量表名称</td>
                <td>申请时间</td>
                <td>申请状态</td>
              </tr>
              <tr v-for="(item, index) in td_case" :key="index">
                <td>
                  <div class="table-name a-e1">{{item.title}}</div>
                </td>
                <td class="a-ta-c">{{item.create_time}}</td>
                <td>
                  <div style="text-align: center;">
                    <span v-if="item.status == 1">已通过</span>
                    <span v-else-if="item.status == 2">未通过</span>
                    <span v-else>审核中</span>
                  </div>
                </td>
              </tr>
            </table>
            <more-list :page="page" :more="more" :nodata="nodata" @moreCallback="moreCallback"></more-list>
          </div>
        </div>
      </div>
    </div>
    <!--底部-->
    <footer-nav></footer-nav>
  </div>
</template>

<script>
import HeaderNav from '@/components/HeaderNav'
import FooterNav from '@/components/FooterNav'
import MoreList from '@/components/MoreList'
import UserLeft from '@/components/UserLeft'

export default {
  name: 'user',
  data () {
    return {
      current: 'user',
      td_case: [],
      sort: 'desc',
      keywords: '',
      page: 1,
      more: false,
      nodata: false
    }
  },
  methods: {
    searchFun: function () {
      this.getList()
    },
    changeSort: function (sort) {
      this.sort = sort
      this.page = 1
      this.getList()
    },
    getList: function () {
      let params = {
        keywords: this.keywords,
        sort: this.sort,
        page: 1,
        limit: 10
      }
      this.$post('user/qualificationList', params).then(res => {
        if (res.code === 1) {
          if (!res.data.totalCount) {
            this.nodata = true
            return false
          } else {
            this.nodata = false
          }
          this.td_case = res.data.listData
          if (res.data.listData.length < res.data.perPage) {
            this.more = false
          } else {
            this.more = true
          }
        } else {
          this.$layer.msg(res.msg)
          this.nodata = true
        }
      }).catch(response => {
        this.$layer.msg('网络异常，请稍后重试！')
        this.nodata = true
      })
    },
    moreCallback: function () {
      let params = {
        keywords: this.keywords,
        sort: this.sort,
        page: ++this.page,
        limit: 10
      }
      this.$post('user/qualificationList', params).then(res => {
        if (res.code === 1) {
          for (let i in res.data.listData) {
            this.td_case.push(res.data.listData[i])
          }
          if (res.data.listData.length < res.data.perPage) {
            this.more = false
          } else {
            this.more = true
          }
        } else {
          this.$layer.msg(res.msg)
          this.more = false
        }
      }).catch(response => {
        this.$layer.msg('网络异常，请稍后重试！')
      })
    },
    seeResult: function (item) {
      if (item.status == 2) {
        this.$router.push('/' + item.type + '?eid=' + item.exam_id + '&id=' + item.title_id)
      } else {
        localStorage.setItem('eid', item.exam_id)
        localStorage.setItem('tid', item.title_id)
        localStorage.setItem('qid', item.position)
        this.$router.push('/test')
      }
    }
  },
  watch: {
    keywords () {
      this.page = 1
      this.getList()
    }
  },
  components: {
    HeaderNav,
    FooterNav,
    MoreList,
    UserLeft
  },
  mounted: function () {
    var h = document.documentElement.clientHeight || document.body.clientHeight
    this.$refs.content.style.minHeight = h - 124 + 'px'
    this.$refs.right.style.minHeight = h - 144 + 'px'
  },
  created () {
    this.getList()
  }
}
</script>

<style lang="less" scoped>
@import url('../assets/css/page/history');
.btn1-box{
  min-width: 60px;
  text-align: center;
}
.btn1-box span{
  color: #f39902;
  padding: 0 5px;
  cursor: pointer;
}
.btn1-box span:hover{
  text-decoration: underline;
}
</style>
